import { useApi } from '@bonliva-traits/api'
import { IAppointment, IMeetingToken } from '@bonliva-traits/api/types'
import { useWebdocNotes } from '@bonliva-traits/webdoc-notes'

import React from 'react'
import { PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'
import {
  MeetingContext,
  MeetingStatus,
  IMeetingSubscriber,
  ScreenShareStatus,
} from './context'
import { MeetingSubscribersMiniature } from './MeetingSubscribersMiniature'
import { useConfig } from '@bonliva-traits/config'
import { UserType } from '@bonliva-traits/api/enums'
import {
  OTError,
  Publisher,
  Subscriber,
  Session,
  Stream,
  EventMap,
  ScreenSharingCapabilityResponse,
} from '@opentok/client'
import OT from '@opentok/client'
import { MediaProcessor } from '@vonage/media-processor'
import FaceAutoZoomTransformer from './transformers/FaceAutoZoomTransformer'

import { MediaProcessorConnector } from '@vonage/media-processor'

type MeetingProviderState = {
  status: MeetingStatus
  screenShareStatus: ScreenShareStatus
  stream?: Stream
  subscriber?: IMeetingSubscriber
  session?: Session
  publisher?: Publisher
  publishAudio: boolean
  publishVideo: boolean
  appointment?: IAppointment
  error?: OTError
  screenSharePublisher?: Publisher
  inMeetingRoom: boolean
  showNotepad: boolean
  notepadText: string
  availableAudioInputDevices: MediaDeviceInfo[]
  availableAudioOutputDevices: MediaDeviceInfo[]
  availableVideoDevices: MediaDeviceInfo[]
  audioInputDevice?: MediaDeviceInfo
  audioOutputDevice?: MediaDeviceInfo
  videoDevice?: MediaDeviceInfo
}

const initialState: MeetingProviderState = {
  status: MeetingStatus.Disconnected,
  screenShareStatus: ScreenShareStatus.NotSupported,
  publishAudio: true,
  publishVideo: true,
  showNotepad: false,
  notepadText: '',
  inMeetingRoom: true,
  availableAudioInputDevices: [],
  availableAudioOutputDevices: [],
  availableVideoDevices: [],
}

type StartMeetingAction = {
  type: 'START_MEETING'
  session: Session
  appointment: IAppointment
}

type SetStatusAction = {
  type: 'SET_STATUS'
  status: MeetingStatus
}

type SetPublisherAction = {
  type: 'SET_PUBLISHER'
  publisher: Publisher
}

type SetStreamAction = {
  type: 'SET_STREAM'
  stream?: Stream
}

type SetErrorAction = {
  type: 'SET_ERROR'
  error: Error
}

type SetPublishAudioAction = {
  type: 'SET_PUBLISH_AUDIO'
  publishAudio: boolean
}

type SetPublishVideoAction = {
  type: 'SET_PUBLISH_VIDEO'
  publishVideo: boolean
}

type SetScreenShareStatusAction = {
  type: 'SET_SCREEN_SHARE_STATUS'
  screenShareStatus: ScreenShareStatus
}

type SetScreenSharePublisherAction = {
  type: 'SET_SCREEN_SHARE_PUBLISHER'
  screenSharePublisher: Publisher
}

type SetInMeetingRoomAction = {
  type: 'SET_IN_MEETING_ROOM'
  inMeetingRoom: boolean
}

type SetSubscriberAction = {
  type: 'SET_SUBSCRIBER'
  subscriber?: IMeetingSubscriber
}

type SetSubscriberHasVideoAction = {
  type: 'SET_SUBSCRIBER_HAS_VIDEO'
  hasVideo: boolean
}

type SetAvailableAudioInputDevicesAction = {
  type: 'SET_AVAILABLE_AUDIO_INPUT_DEVICES'
  devices: MediaDeviceInfo[]
}

type SetAvailableAudioOutputDevicesAction = {
  type: 'SET_AVAILABLE_AUDIO_OUTPUT_DEVICES'
  devices: MediaDeviceInfo[]
}

type SetAvailableVideoDevicesAction = {
  type: 'SET_AVAILABLE_VIDEO_DEVICES'
  devices: MediaDeviceInfo[]
}

type SetUsedAudioInputDeviceAction = {
  type: 'SET_USED_AUDIO_INPUT_DEVICE'
  device: MediaDeviceInfo
}

type SetUsedAudioOutputDeviceAction = {
  type: 'SET_USED_AUDIO_OUTPUT_DEVICE'
  device: MediaDeviceInfo
}

type SetUsedVideoDeviceAction = {
  type: 'SET_USED_VIDEO_DEVICE'
  device: MediaDeviceInfo
}

type MeetingProviderAction =
  | StartMeetingAction
  | SetStatusAction
  | SetPublisherAction
  | SetStreamAction
  | SetErrorAction
  | SetPublishAudioAction
  | SetPublishVideoAction
  | SetScreenShareStatusAction
  | SetScreenSharePublisherAction
  | SetInMeetingRoomAction
  | SetSubscriberAction
  | SetSubscriberHasVideoAction
  | SetAvailableAudioInputDevicesAction
  | SetAvailableAudioOutputDevicesAction
  | SetAvailableVideoDevicesAction
  | SetUsedAudioInputDeviceAction
  | SetUsedAudioOutputDeviceAction
  | SetUsedVideoDeviceAction

export const MeetingProvider: React.FC<PropsWithChildren> = (props) => {
  const webdocContext = useWebdocNotes()
  const location = useLocation()
  const { TOKBOX_VIDEO_API_KEY } = useConfig()
  const api = useApi()
  const [state, dispatch] = React.useReducer(
    (state: MeetingProviderState, action: MeetingProviderAction) => {
      switch (action.type) {
        case 'START_MEETING':
          return {
            ...initialState,
            publishAudio: state.publishAudio,
            publishVideo: state.publishVideo,
            session: action.session,
            appointment: action.appointment,
            screenShareStatus: state.screenShareStatus,
            availableAudioInputDevices: state.availableAudioInputDevices,
            availableAudioOutputDevices: state.availableAudioOutputDevices,
            availableVideoDevices: state.availableVideoDevices,
            audioInputDevice: state.audioInputDevice,
            audioOutputDevice: state.audioOutputDevice,
            videoDevice: state.videoDevice,
          }
        case 'SET_STATUS':
          return {
            ...state,
            status: action.status,
          }
        case 'SET_PUBLISHER':
          return {
            ...state,
            publisher: action.publisher,
          }
        case 'SET_STREAM':
          return {
            ...state,
            stream: action.stream,
          }
        case 'SET_ERROR':
          return {
            ...state,
            error: action.error,
            status: MeetingStatus.Failed,
          }
        case 'SET_PUBLISH_AUDIO':
          return {
            ...state,
            publishAudio: action.publishAudio,
          }
        case 'SET_PUBLISH_VIDEO':
          return {
            ...state,
            publishVideo: action.publishVideo,
          }
        case 'SET_SCREEN_SHARE_STATUS':
          return {
            ...state,
            screenShareStatus: action.screenShareStatus,
          }
        case 'SET_SCREEN_SHARE_PUBLISHER':
          return {
            ...state,
            screenSharePublisher: action.screenSharePublisher,
          }
        case 'SET_IN_MEETING_ROOM':
          return {
            ...state,
            inMeetingRoom: action.inMeetingRoom,
          }
        case 'SET_SUBSCRIBER':
          return {
            ...state,
            subscriber: action.subscriber,
          }
        case 'SET_SUBSCRIBER_HAS_VIDEO':
          return {
            ...state,
            subscriber: state.subscriber
              ? {
                  ...state.subscriber,
                  hasVideo: action.hasVideo,
                }
              : undefined,
          }
        case 'SET_AVAILABLE_AUDIO_INPUT_DEVICES':
          return {
            ...state,
            availableAudioInputDevices: action.devices,
          }
        case 'SET_AVAILABLE_AUDIO_OUTPUT_DEVICES':
          return {
            ...state,
            availableAudioOutputDevices: action.devices,
          }
        case 'SET_AVAILABLE_VIDEO_DEVICES':
          return {
            ...state,
            availableVideoDevices: action.devices,
          }
        case 'SET_USED_AUDIO_INPUT_DEVICE':
          return {
            ...state,
            audioInputDevice: action.device,
          }
        case 'SET_USED_AUDIO_OUTPUT_DEVICE':
          return {
            ...state,
            audioOutputDevice: action.device,
          }
        case 'SET_USED_VIDEO_DEVICE':
          return {
            ...state,
            videoDevice: action.device,
          }
        default:
          return state
      }
    },
    initialState
  )

  React.useEffect(() => {
    if (state.screenSharePublisher) {
      state.screenSharePublisher.on('streamDestroyed', () => {
        dispatch({
          type: 'SET_SCREEN_SHARE_STATUS',
          screenShareStatus: ScreenShareStatus.NotSharing,
        })
      })

      return () => {
        state.screenSharePublisher?.off('streamDestroyed')
      }
    }
  }, [state.screenSharePublisher])

  React.useEffect(() => {
    OT.checkScreenSharingCapability(
      (capabilities: ScreenSharingCapabilityResponse) => {
        if (capabilities.supported) {
          dispatch({
            type: 'SET_SCREEN_SHARE_STATUS',
            screenShareStatus: ScreenShareStatus.NotSharing,
          })
        }
      }
    )
  }, [])

  const initPublisher = (
    publishAudio?: boolean,
    publishVideo?: boolean,
    videoDeviceId?: string
  ) => {
    const publisherContainer = document.createElement('div')
    const publisher = OT.initPublisher(
      publisherContainer,
      {
        videoSource: videoDeviceId ?? state.videoDevice?.deviceId ?? false,
      },
      (error?: Error) => {
        publisher.publishAudio(publishAudio ?? state.publishAudio)
        publisher.publishVideo(publishVideo ?? state.publishVideo)
        const faceAutoZoomTransformer = new FaceAutoZoomTransformer()

        faceAutoZoomTransformer.init().then(() => {
          const mediaProcessor = new MediaProcessor()
          mediaProcessor.setTransformers([faceAutoZoomTransformer]).then(() => {
            return publisher.setVideoMediaProcessorConnector(
              new MediaProcessorConnector(mediaProcessor)
            )
          })
        })

        if (error) {
          dispatch({ type: 'SET_ERROR', error })
        } else {
          state.session?.publish(publisher, (error) => {
            if (error) {
              dispatch({ type: 'SET_ERROR', error })
            } else {
              dispatch({ type: 'SET_PUBLISHER', publisher })
            }
          })
        }
      }
    )

    publisher.on({
      // Sometimes this event gets triggered after the patient leaves the meeting
      streamDestroyed: (e: Event) => {
        e.preventDefault()
        publisher.off('streamDestroyed')
        publisherContainer.remove()
        // state.publishAudio here is stale and therefore true because of how state works in callbacks :(
        // so we need to get the actual value from the publisher's audioSource
        initPublisher(
          publisher.getAudioSource()?.enabled,
          publisher.getVideoSource()?.track !== null,
          publisher.getVideoSource()?.track?.getSettings().deviceId
        )
      },
    })
    return publisher
  }

  React.useEffect(() => {
    if (state.status === MeetingStatus.Connected) {
      initPublisher()
    }
  }, [state.status, state.session])

  React.useEffect(() => {
    state.session?.on({
      streamPropertyChanged: (event: EventMap['streamPropertyChanged']) => {
        if (event.stream.streamId === state.publisher?.stream?.streamId) {
          if (event.changedProperty === 'hasAudio') {
            dispatch({
              type: 'SET_PUBLISH_AUDIO',
              publishAudio: !!event.newValue,
            })
          }

          if (event.changedProperty === 'hasVideo') {
            dispatch({
              type: 'SET_PUBLISH_VIDEO',
              publishVideo: !!event.newValue,
            })
          }
        }
      },
    })

    return () => {
      state.session?.off('streamPropertyChanged')
    }
  }, [state.publisher, state.session])

  React.useEffect(() => {
    if (state.status === MeetingStatus.Connected) {
      const handler = (event: BeforeUnloadEvent) => {
        event.preventDefault()
        event.returnValue = 'Vill du verkligen lämna samtalet?'
        return 'Vill du verkligen lämna samtalet?'
      }

      window.addEventListener('beforeunload', handler)

      return () => {
        window.removeEventListener('beforeunload', handler)
      }
    }
  }, [state.status])

  React.useEffect(() => {
    if (
      state.status === MeetingStatus.Connected &&
      !location.pathname.endsWith('/meeting')
    ) {
      dispatch({ type: 'SET_IN_MEETING_ROOM', inMeetingRoom: false })
    } else {
      dispatch({ type: 'SET_IN_MEETING_ROOM', inMeetingRoom: true })
    }
  }, [location.pathname, state.status])

  const startMeeting = async (appointmentId: string) => {
    return new Promise<void>(async (resolve, reject) => {
      dispatch({ type: 'SET_STATUS', status: MeetingStatus.Connecting })

      const [{ data: tokenData }, { data: appointment }] = await Promise.all([
        api.get<IMeetingToken>(
          `/v1/treaters/appointments/${appointmentId}/connect`
        ),
        api.get<IAppointment>(`/v1/treaters/appointments/${appointmentId}`),
      ])

      const session = OT.initSession(TOKBOX_VIDEO_API_KEY, tokenData.sessionId)

      session.on({
        streamCreated: (event: EventMap['streamCreated']) => {
          try {
            const parsedData = JSON.parse(event.stream.connection.data)
            if (parsedData['userType'] === UserType.Treater) {
              return
            }
          } catch (_) {}
          // If newer than existing stream, replace it
          if (
            !state.stream ||
            new Date(event.stream.creationTime).getTime() >
              new Date(state.stream.creationTime).getTime()
          ) {
            dispatch({ type: 'SET_STREAM', stream: event.stream })
          }
        },
        streamDestroyed: (event: EventMap['streamDestroyed']) => {
          if (state.stream?.streamId !== event.stream.streamId) {
            return
          }
          dispatch({ type: 'SET_STREAM', stream: undefined })
        },
      })

      session.connect(tokenData.token, (error) => {
        if (error) {
          dispatch({ type: 'SET_ERROR', error })
          reject(error)
        } else {
          dispatch({ type: 'SET_STATUS', status: MeetingStatus.Connected })
          resolve()
        }
      })

      if (state.appointment?.id !== appointmentId) {
        webdocContext.unload()
        dispatch({
          type: 'START_MEETING',
          session,
          appointment,
        })
      }
    })
  }

  const stopMeeting = async (appointmentId: string) => {
    state.publisher?.destroy()
    state.session?.disconnect()

    api.put(`/v1/treaters/appointments/${appointmentId}/leave`)

    const subscriber = state.stream
      ? state.session?.getSubscribersForStream(state.stream) || []
      : []
    if (subscriber[0]) {
      state.session?.unsubscribe(subscriber[0])
      dispatch({ type: 'SET_STREAM', stream: undefined })
    }

    dispatch({ type: 'SET_STATUS', status: MeetingStatus.Disconnected })
  }

  const setPublishAudio = (enabled: boolean) => {
    if (state.publisher) {
      state.publisher.publishAudio(enabled)
    } else {
      dispatch({ type: 'SET_PUBLISH_AUDIO', publishAudio: enabled })
    }
  }

  const setPublishVideo = (enabled: boolean) => {
    if (state.publisher) {
      state.publisher.publishVideo(enabled)
    } else {
      dispatch({ type: 'SET_PUBLISH_VIDEO', publishVideo: enabled })
    }
  }

  const startScreenShare = () => {
    dispatch({
      type: 'SET_SCREEN_SHARE_STATUS',
      screenShareStatus: ScreenShareStatus.Starting,
    })
    const publisherContainer = document.createElement('div')
    const publisher = OT.initPublisher(
      publisherContainer,
      { videoSource: 'screen' },
      (error?: Error) => {
        if (error) {
          if (error.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
            dispatch({
              type: 'SET_SCREEN_SHARE_STATUS',
              screenShareStatus: ScreenShareStatus.NotSharing,
            })
          } else {
            dispatch({ type: 'SET_ERROR', error })
          }
        } else {
          dispatch({
            type: 'SET_SCREEN_SHARE_PUBLISHER',
            screenSharePublisher: publisher,
          })
          dispatch({
            type: 'SET_SCREEN_SHARE_STATUS',
            screenShareStatus: ScreenShareStatus.Sharing,
          })
          state.session?.publish(publisher, (error) => {
            if (error) {
              dispatch({ type: 'SET_ERROR', error })
            }
          })
        }
      }
    )
  }

  const stopScreenShare = () => {
    state.screenSharePublisher?.destroy()
    dispatch({
      type: 'SET_SCREEN_SHARE_STATUS',
      screenShareStatus: ScreenShareStatus.NotSharing,
    })
  }

  const [subscriberVideoElement, setSubscriberVideoElement] = React.useState<{
    element: HTMLElement
    streamId: string
  }>()

  React.useEffect(() => {
    // If we have a stream, and it is the same as the subscriber we have done this already
    if (state.stream && state.stream?.streamId === state.subscriber?.streamId) {
      return
    }

    // We are swapping our subscriber to a new stream, or just removing it
    if (state.subscriber) {
      setSubscriberVideoElement(undefined)
      dispatch({ type: 'SET_SUBSCRIBER', subscriber: undefined })
    }

    if (!state.stream) {
      return
    }

    const newSubscriber = state.session?.subscribe(state.stream, undefined, {
      insertDefaultUI: false,
    })

    if (!newSubscriber) {
      return
    }

    newSubscriber.on('videoElementCreated', (event) => {
      if (!state.stream) {
        return
      }
      dispatch({
        type: 'SET_SUBSCRIBER',
        subscriber: {
          subscriber: newSubscriber,
          streamId: state.stream.streamId,
          videoElement: event.element,
          hasVideo: !!(event.target as Subscriber).stream?.hasVideo,
        },
      })
    })

    newSubscriber.on('videoDisabled', () => {
      dispatch({ type: 'SET_SUBSCRIBER_HAS_VIDEO', hasVideo: false })
    })
    newSubscriber.on('videoEnabled', () => {
      dispatch({ type: 'SET_SUBSCRIBER_HAS_VIDEO', hasVideo: true })
    })

    newSubscriber.on('videoElementCreated', (event) => {
      setSubscriberVideoElement((prev) =>
        state.stream
          ? {
              ...prev,
              element: event.element,
              streamId: state.stream.streamId,
            }
          : prev
      )
    })

    dispatch({
      type: 'SET_SUBSCRIBER',
      subscriber: {
        streamId: state.stream.streamId,
        subscriber: newSubscriber,
        hasVideo: false,
      },
    })

    return () => {
      newSubscriber.off('videoElementCreated')
      newSubscriber.off('videoDisabled')
      newSubscriber.off('videoEnabled')
    }
  }, [state.stream])

  const getDevices = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
    const devices = await navigator.mediaDevices.enumerateDevices()
    const audioInputDevices = devices.filter(
      (device) => device.kind === 'audioinput'
    )
    const audioOutputDevices = devices.filter(
      (device) => device.kind === 'audiooutput'
    )
    const videoDevices = devices.filter(
      (device) => device.kind === 'videoinput'
    )

    dispatch({
      type: 'SET_AVAILABLE_AUDIO_INPUT_DEVICES',
      devices: audioInputDevices,
    })
    dispatch({
      type: 'SET_AVAILABLE_AUDIO_OUTPUT_DEVICES',
      devices: audioOutputDevices,
    })
    dispatch({
      type: 'SET_AVAILABLE_VIDEO_DEVICES',
      devices: videoDevices,
    })

    if (!state.audioInputDevice) {
      setAudioInputDevice(audioInputDevices[0])
    }
    if (!state.audioOutputDevice) {
      setAudioOutputDevice(audioOutputDevices[0])
    }
    if (!state.videoDevice) {
      setVideoDevice(videoDevices[0])
    }
  }

  React.useEffect(() => {
    getDevices()
  }, [state.session, state.publisher])

  React.useEffect(() => {
    if (!subscriberVideoElement) {
      return
    }

    // This element is incorrectly typed
    const element = subscriberVideoElement.element as any
    if (
      !state.audioOutputDevice ||
      !element ||
      typeof element.sinkId === 'undefined'
    ) {
      return
    }
    element.setSinkId?.(state.audioOutputDevice.deviceId)
  }, [subscriberVideoElement, state.audioOutputDevice])

  const setAudioInputDevice = (device: MediaDeviceInfo) => {
    dispatch({ type: 'SET_USED_AUDIO_INPUT_DEVICE', device })

    if (!state.publisher) {
      return
    }

    state.publisher.setAudioSource(device.deviceId)
  }

  const setAudioOutputDevice = (device: MediaDeviceInfo) => {
    // Note: This is handled in the useEffect above
    dispatch({ type: 'SET_USED_AUDIO_OUTPUT_DEVICE', device })
  }

  const setVideoDevice = (device: MediaDeviceInfo) => {
    dispatch({ type: 'SET_USED_VIDEO_DEVICE', device })
    if (!state.publisher) {
      return
    }
    state.publisher.off('streamDestroyed')
    state.publisher.destroy()
    initPublisher(state.publishAudio, state.publishVideo, device.deviceId)
  }

  return (
    <MeetingContext.Provider
      value={{
        startMeeting,
        stopMeeting,
        setPublishAudio,
        setPublishVideo,
        startScreenShare,
        stopScreenShare,
        setAudioInputDevice,
        setAudioOutputDevice,
        setVideoDevice,
        getDevices,
        appointment: state.appointment,
        status: state.status,
        screenSharingStatus: state.screenShareStatus,
        session: state.session,
        publisher: state.publisher,
        stream: state.stream,
        subscriber: state.subscriber,
        publishAudio: state.publishAudio,
        publishVideo: state.publishVideo,
        screenSharePublisher: state.screenSharePublisher,
        availableAudioInputDevices: state.availableAudioInputDevices,
        availableAudioOutputDevices: state.availableAudioOutputDevices,
        availableVideoDevices: state.availableVideoDevices,
        audioInputDevice: state.audioInputDevice,
        audioOutputDevice: state.audioOutputDevice,
        videoDevice: state.videoDevice,
      }}
    >
      {!state.inMeetingRoom && <MeetingSubscribersMiniature />}
      {props.children}
    </MeetingContext.Provider>
  )
}
