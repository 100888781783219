import {
  MediaPipeModelType,
  FaceDetectionResults,
  MediaPipeResults,
  MediapipeHelper,
  FaceDetectionOptions,
  MediapipeConfig,
} from '@vonage/ml-transformers'
import { MediapipeResultsListnerInterface } from '../interfaces/MediapipeInterfaces'

const FACE_DETECTION_TIME_GAP = 20000

interface Coord {
  x: number
  y: number
}

interface Size {
  width: number
  height: number
}
interface VideoInfo extends Size {
  frameRate: number
}

class MediapipeTransformer implements MediapipeResultsListnerInterface {
  mediapipeCanvas_: OffscreenCanvas
  mediapipeCtx_?: OffscreenCanvasRenderingContext2D

  faceDetectionlastTimestamp = 0
  videoInfo: VideoInfo = {
    height: 844,
    width: 390,

    frameRate: 30,
  }
  padding: Size = {
    width: 60,
    height: 240,
  }
  visibleRectDimension?: any
  visibleRectDimensionState?: any

  frameMovingSteps: Size & Coord = {
    x: 1,
    y: 1,
    width: 1,
    height: 1,
  }

  modelType: MediaPipeModelType = 'face_detection'
  modelOptions: FaceDetectionOptions = {
    selfieMode: false,
    minDetectionConfidence: 0.5,
    model: 'short',
  }
  mediaHelper: MediapipeHelper = new MediapipeHelper()
  constructor() {
    this.mediapipeCanvas_ = new OffscreenCanvas(1, 1)
    const ctx = this.mediapipeCanvas_.getContext('2d', {
      alpha: false,
      desynchronized: true,
    })
    if (ctx) {
      this.mediapipeCtx_ = ctx
    } else {
      throw new Error('Unable to create OffscreenCanvasRenderingContext2D')
    }
  }

  onResult(result: MediaPipeResults | ImageBitmap): void {
    // if (result instanceof ImageBitmap) {
    //   return
    // }
    const faceDetectionresult = result as FaceDetectionResults

    if (faceDetectionresult.detections.length > 0) {
      this.calculateDimensions(faceDetectionresult)
    }
  }

  init(): Promise<void> {
    const config: MediapipeConfig = {
      mediaPipeModelConfigArray: [
        {
          listener: (result: MediaPipeResults): void => {
            this.onResult(result)
          },
          modelType: this.modelType,
          options: this.modelOptions,
        },
      ],
    }
    return this.mediaHelper.initialize(config)
  }

  transform(frame: VideoFrame, controller: TransformStreamDefaultController) {
    if (
      this.mediapipeCanvas_.width != frame.displayWidth ||
      this.mediapipeCanvas_.height != frame.displayHeight
    ) {
      this.mediapipeCanvas_.width = frame.displayWidth
      this.mediapipeCanvas_.height = frame.displayHeight
    }

    const timestamp = frame.timestamp
    createImageBitmap(frame)
      .then((image) => {
        frame.close()
        return this.processFrame(
          image,
          timestamp ? timestamp : Date.now(),
          controller
        )
      })
      .catch((e) => {
        console.error(e)
        controller.enqueue(frame)
      })
  }

  updateVisibleRectDimension() {
    if (
      this.visibleRectDimensionState.visibleRectX !==
      this.visibleRectDimension.visibleRectX
    ) {
      this.visibleRectDimensionState.visibleRectX =
        this.visibleRectDimensionState.visibleRectX >
        this.visibleRectDimension.visibleRectX
          ? this.visibleRectDimensionState.visibleRectX -
            this.frameMovingSteps.x
          : this.visibleRectDimensionState.visibleRectX +
            this.frameMovingSteps.x
    }

    if (
      this.visibleRectDimensionState.visibleRectY !==
      this.visibleRectDimension.visibleRectY
    ) {
      this.visibleRectDimensionState.visibleRectY =
        this.visibleRectDimensionState.visibleRectY >
        this.visibleRectDimension.visibleRectY
          ? this.visibleRectDimensionState.visibleRectY -
            this.frameMovingSteps.y
          : this.visibleRectDimensionState.visibleRectY +
            this.frameMovingSteps.y
    }

    if (
      this.visibleRectDimensionState.visibleRectWidth !==
      this.visibleRectDimension.visibleRectWidth
    ) {
      this.visibleRectDimensionState.visibleRectWidth =
        this.visibleRectDimensionState.visibleRectWidth >
        this.visibleRectDimension.visibleRectWidth
          ? this.visibleRectDimensionState.visibleRectWidth -
            this.frameMovingSteps.width
          : this.visibleRectDimensionState.visibleRectWidth +
            this.frameMovingSteps.width
    }

    if (
      this.visibleRectDimensionState.visibleRectHeight !==
      this.visibleRectDimension.visibleRectHeight
    ) {
      this.visibleRectDimensionState.visibleRectHeight =
        this.visibleRectDimensionState.visibleRectHeight >
        this.visibleRectDimension.visibleRectHeight
          ? this.visibleRectDimensionState.visibleRectHeight -
            this.frameMovingSteps.height
          : this.visibleRectDimensionState.visibleRectHeight +
            this.frameMovingSteps.height
    }

    if (
      Math.abs(
        this.visibleRectDimensionState.visibleRectX -
          this.visibleRectDimension.visibleRectX
      ) <= this.frameMovingSteps.x
    ) {
      this.visibleRectDimensionState.visibleRectX =
        this.visibleRectDimension.visibleRectX
    }

    if (
      Math.abs(
        this.visibleRectDimensionState.visibleRectY -
          this.visibleRectDimension.visibleRectY
      ) <= this.frameMovingSteps.y
    ) {
      this.visibleRectDimensionState.visibleRectY =
        this.visibleRectDimension.visibleRectY
    }

    if (
      Math.abs(
        this.visibleRectDimensionState.visibleRectWidth -
          this.visibleRectDimension.visibleRectWidth
      ) <= this.frameMovingSteps.width
    ) {
      this.visibleRectDimensionState.visibleRectWidth =
        this.visibleRectDimension.visibleRectWidth
    }

    if (
      Math.abs(
        this.visibleRectDimensionState.visibleRectHeight -
          this.visibleRectDimension.visibleRectHeight
      ) <= this.frameMovingSteps.height
    ) {
      this.visibleRectDimensionState.visibleRectHeight =
        this.visibleRectDimension.visibleRectHeight
    }

    const deltaX =
      this.visibleRectDimensionState.visibleRectX +
      this.visibleRectDimensionState.visibleRectWidth

    const deltaY =
      this.visibleRectDimensionState.visibleRectY +
      this.visibleRectDimensionState.visibleRectHeight

    if (deltaX > this.videoInfo.width)
      this.visibleRectDimensionState.visibleRectWidth =
        this.visibleRectDimensionState.visibleRectWidth -
        (deltaX - this.videoInfo.width)
    if (deltaY > this.videoInfo.height)
      this.visibleRectDimensionState.visibleRectHeight =
        this.visibleRectDimensionState.visibleRectHeight -
        (deltaY - this.videoInfo.height)
  }

  async processFrame(
    image: ImageBitmap,
    timestamp: number,
    controller: TransformStreamDefaultController
  ) {
    if (
      timestamp - this.faceDetectionlastTimestamp >=
      FACE_DETECTION_TIME_GAP
    ) {
      this.faceDetectionlastTimestamp = timestamp
      this.mediapipeProcess(image)
    }

    if (this.visibleRectDimension) {
      this.updateVisibleRectDimension()

      const resizeFrame = new VideoFrame(image, {
        visibleRect: {
          x: this.visibleRectDimensionState.visibleRectX,
          y: this.visibleRectDimensionState.visibleRectY,
          width: this.visibleRectDimensionState.visibleRectWidth,
          height: this.visibleRectDimensionState.visibleRectHeight,
        },
        timestamp,
        alpha: 'discard',
      })
      controller.enqueue(resizeFrame)
    } else {
      controller.enqueue(new VideoFrame(image, { timestamp, alpha: 'discard' }))
    }
    image.close()
  }

  mediapipeProcess(image: ImageBitmap) {
    if (
      this.videoInfo.width !== image.width ||
      this.videoInfo.height !== image.height
    ) {
      this.videoInfo.width = image.width
      this.videoInfo.height = image.height
    }
    this.mediapipeCtx_!.clearRect(
      0,
      0,
      this.mediapipeCanvas_.width,
      this.mediapipeCanvas_.height
    )
    this.mediapipeCtx_?.drawImage(
      image,
      0,
      0,
      image.width,
      image.height,
      0,
      0,
      this.mediapipeCanvas_.width,
      this.mediapipeCanvas_.height
    )
    this.mediaHelper.send(this.mediapipeCanvas_.transferToImageBitmap())
  }

  calculateDimensions(faceDetectionresult: FaceDetectionResults) {
    if (!faceDetectionresult.detections[0]) return
    const newWidth = Math.floor(
      faceDetectionresult.detections[0].boundingBox.width *
        this.videoInfo.width +
        this.padding.width * 2
    )
    const newHeight =
      Math.floor(
        faceDetectionresult.detections[0].boundingBox.height *
          this.videoInfo.height +
          this.padding.height * 4
      ) * 1.4
    // const newHeight = this.videoInfo.height
    let newX =
      Math.floor(
        faceDetectionresult.detections[0].boundingBox.xCenter *
          this.videoInfo.width -
          (faceDetectionresult.detections[0].boundingBox.width *
            this.videoInfo.width) /
            2
      ) - this.padding.width
    newX = Math.max(0, newX)
    let newY =
      Math.floor(
        faceDetectionresult.detections[0].boundingBox.yCenter *
          this.videoInfo.height -
          (faceDetectionresult.detections[0].boundingBox.height *
            this.videoInfo.height) /
            2
      ) - this.padding.height
    newY = Math.max(0, newY)

    if (
      !this.visibleRectDimension ||
      Math.abs(newX - this.visibleRectDimension.visibleRectX) > 10 ||
      Math.abs(newY - this.visibleRectDimension.visibleRectY) > 10
    ) {
      // Ensure x and y is even value
      const visibleRectX = newX % 2 === 0 ? newX : newX + 1
      const visibleRectY = newY % 2 === 0 ? newY : newY + 1
      // Ensure visibleRectWidth and visibleRectHeight fall within videoWidth and videoHeight
      const visibleRectWidth =
        visibleRectX + newWidth > this.videoInfo.width
          ? this.videoInfo.width - visibleRectX
          : newWidth
      const visibleRectHeight =
        visibleRectY + newHeight > this.videoInfo.height
          ? this.videoInfo.height - visibleRectY
          : newHeight
      this.visibleRectDimension = {
        visibleRectX,
        visibleRectY,
        visibleRectWidth,
        visibleRectHeight,
      }
      if (!this.visibleRectDimensionState)
        this.visibleRectDimensionState = this.visibleRectDimension
      else {
        this.frameMovingSteps = {
          x: Math.max(
            Math.floor(
              Math.abs(
                this.visibleRectDimensionState.visibleRectX -
                  this.visibleRectDimension.visibleRectX
              ) /
                (this.videoInfo.frameRate / 5)
            ),
            1
          ),
          y: Math.max(
            Math.floor(
              Math.abs(
                this.visibleRectDimensionState.visibleRectY -
                  this.visibleRectDimension.visibleRectY
              ) /
                (this.videoInfo.frameRate / 5)
            ),
            1
          ),
          width: Math.max(
            Math.floor(
              Math.abs(
                this.visibleRectDimensionState.visibleRectWidth -
                  this.visibleRectDimension.visibleRectWidth
              ) /
                (this.videoInfo.frameRate / 5)
            ),
            1
          ),
          height: Math.max(
            Math.floor(
              Math.abs(
                this.visibleRectDimensionState.visibleRectHeight -
                  this.visibleRectDimension.visibleRectHeight
              ) /
                (this.videoInfo.frameRate / 5)
            ),
            1
          ),
        }
      }
    }
  }
}
export default MediapipeTransformer
